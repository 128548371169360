import { FaRegHandshake } from 'react-icons/fa6';
import { MdPayments } from 'react-icons/md';
import { AiOutlineDeliveredProcedure } from 'react-icons/ai';
import { MdOutlineApproval } from 'react-icons/md';
import { RiExchangeFundsFill } from 'react-icons/ri';
import { GiAmericanShield } from 'react-icons/gi';
import { FaCode } from 'react-icons/fa6';
import face from './Hero2.png'

export const navLinks = [
    {
      id: 1,
      name: 'home',
      link: '/'
    },
    {
      id: 2,
      name: 'pricing',
      link: '/pricing',
    },
    {
      id: 3,
      name: 'blog',
      link: 'https://blog.isimplify.ng',
    },
];
 
export const process = [
    {
        id: 1,
        icon: <FaRegHandshake />,
        text: 'Buyer and Seller agree to terms',
    },
    {
        id: 2,
        icon: <MdPayments />,
        text: 'Buyer submits payment to iSimplify Trust',
    },
    {
        id: 3,
        icon: <AiOutlineDeliveredProcedure />,
        text: 'Seller delivers goods or service to buyer',
    },
    {
        id: 4,
        icon: <MdOutlineApproval />,
        text: 'Buyer approves goods or services',
    },
    {
        id: 5,
        icon: <RiExchangeFundsFill />,
        text: 'iSimplify Trust releases payment to seller',
    },
];

export const docs = [
    {
        id: 1,
        icon: <FaCode style={{color: '#3ef12e'}} />,
        title: 'Built for developers, by developers',
        text: 'Any business can integrate the safety and security of iSimplify Trust payments to their platform.'
    },
    {
        id: 2,
        icon: <GiAmericanShield style={{color: '#1D2EF3'}} />,
        title: 'Protecting both buyers and sellers',
        text: 'With iSimplify Trust, buyers can inspect goods or services beforehand for buyer protection, while sellers are safeguarded from counterparty risk with no chargebacks.'
    },
];

export const footerLinks = [
    {
        title: 'company' ,
        dir: [
            {
                id: 1,
                name: 'about us',
                url: ''
            },
            {
                id: 2,
                name: 'how it works',
                url: ''
            },
            {
                id: 3,
                name: 'contact us',
                url: ''
            },
        ],
    },

    {
        title: 'products' ,
        dir: [
            {
                id: 1,
                name: 'escrow',
                url: ''
            },
            {
                id: 2,
                name: 'delivery',
                url: ''
            },
            {
                id: 3,
                name: 'store',
                url: ''
            },
        ],
    },

    {
        title: 'resources' ,
        dir: [
            {
                id: 1,
                name: 'pricing',
                url: '/pricing'
            },
            {
                id: 2,
                name: 'API documentation',
                url: ''
            },
            {
                id: 3,
                name: 'Blog',
                url: 'https://blog.isimplify.ng'
            },
            {
                id: 4,
                name: 'FAQs',
                url: ''
            },
        ],
    },
];


export const testimonial = [
    {
        image: face,
        name: 'Bayo O.',
        word: "I was skeptical at first, but this escrow platform exceeded my expectations. It ensured a smooth and reliable transaction, giving me peace of mind. I'll definitely use it again!",
    },
    {
        image: face,
        name: 'Chibuzo P.',
        word: "I can't imagine conducting online transactions without this escrow service. It protected me from potential scams and ensured that I received exactly what I paid for. It's an absolute must-have!",
    }, 
    {
        image: face,
        name: 'Haruna L.',
        word: "I'm extremely impressed with this escrow platform. It provided a transparent and fair process, ensuring that both parties were satisfied with the transaction. I highly recommend it for any online trade."
    }
];