import React, { useState, useEffect } from 'react';
//import postFetch from '../Components/Api/blogData';
import axios from 'axios';
//import { BiDotsHorizontalRounded } from "react-icons/bi";
import { Icon } from '@iconify/react';
import ReactPaginate from 'react-paginate';


const Blog = () => {
  // json URL to fetch from
  const url = 'https://corsproxy.io/?' + encodeURIComponent('https://blog.isimplify.ng/wp-json/wp/v2/posts');
  
  const [posts, setPosts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  

  useEffect(()=> {
    const fetchBlogData = async() => {
      // Check local storage for existing data
      const localData = localStorage.getItem('blogPost');
      if (localData) {
        setIsLoaded(true);
        setPosts(JSON.parse(localData));
        return;
      }

      try{
        const response = await axios.get(url, {headers:{'Access-Control-Allow-Origin':'*', 'Accept':'application/json'}});
        const postData = response.data;
        //console.log(response.data);


        const postDataDetailsFetch = postData.map(item =>{
          const authorUrl = axios.get('https://corsproxy.io/?' + encodeURIComponent(`https://blog.isimplify.ng/wp-json/wp/v2/users/${item.author}`), {headers:{'Access-Control-Allow-Origin':'*', 'Accept':'application/json'}});
          const imageUrl =  axios.get('https://corsproxy.io/?' + encodeURIComponent(`https://blog.isimplify.ng/wp-json/wp/v2/media?parent=${item.id}`), {headers:{'Access-Control-Allow-Origin':'*', 'Accept':'application/json'}});
          
          return Promise.all([authorUrl, imageUrl])
            .then(([authorUrl, imageUrl]) => {
              const authorData = authorUrl.data;
              const imageData = imageUrl.data;

              return {
                id: item.id,
                title: item.title.rendered,
                author: authorData.name,
                imageURL: imageData.length > 0 ? imageData[0].source_url : '', 
                postURL: item.link, 
              };
            })
            .catch(err => {
              console.log(err.message);
              return null;
            });
        }); 

        const postDatas = await Promise.all(postDataDetailsFetch);
        const filteredPosts = postDatas.filter(post => post !== null);
        setPosts(filteredPosts);
        setIsLoaded(true)

        // save data to local storage
        localStorage.setItem('blogPost', JSON.stringify(filteredPosts));
      }
      catch(err){
        console.log(err.message)
      };
    } 
    fetchBlogData()
  }, [url]);


  // declared pagination variable and states
  const paginatePost = posts; 
  const [pageNumber, setPageNumber]= useState(0);
  //console.log(paginatePost)

  const postsPerPage = 6; // amount of posts to beshown on a page
  const visitedPages = pageNumber * postsPerPage; // pages already visited

  const displayBlogPosts = paginatePost
  .slice(visitedPages, visitedPages + postsPerPage)
  .map((post) => {
    return (
    <div className="rounded-[8px]  bg-[#f9f9f9] w-full lg:w-[31%] hover:shadow-sm" key={post.id}>
      <div className="w-full h-[60%] ">
        {post.imageURL && <img src={post.imageURL} alt="Post" className="w-[100%] blogCard"   />}
      </div>
      <div className="py-2 px-4">
        <h2 className="text-[18px] text-dark font-semibold font-kanit">{post.title}</h2>
      </div>
      <div className="flex px-4 gap-3 items-center">
        <Icon icon="fluent:person-12-filled" className="text-primary text-25px" />
        <h5 className="font-semibold text-dark">{post.author}</h5>
      </div>
      <div className="rounded-[30px] bg-primary pl-3 pr-1 py-[3px] my-4 mx-4 flex items-center justify-between w-3/6">
        <h6 className="text-white ">Quick read</h6>
        <div className="rounded-[30px] bg-secondary p-2">
          <a href={post.postURL} target="_blank" rel="noopener noreferrer">
            <Icon icon="bi:arrow-up-right" className="text-white" />
          </a>
        </div>
      </div>             
    </div>
    )    
  });
  

  // calculating page count for pagination 
  const pageCount = Math.ceil(paginatePost.length / postsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // Managing loadStates and adding structure
  const componentDidMount = () => {
    if(isLoaded) {
      return (
        <div className="w-5/6 mx-auto pt-5 ">
          <div className="flex flex-wrap gap-9 items-center  ">
            {displayBlogPosts}
          </div>
          
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={changePage}
            pageLinkClassName={"paginateBtns"}
            previousLinkClassName={"previousBtn"}
            nextLinkClassName={"nextBtn"}
            disabledClassName={"disabledBtn"}
            activeLinkClassName={"activeBtn"}
            className='flex items-center justify-center mx-auto gap-5 my-8'
          />
        </div>
      );
    };
    return <div className='w-full flex items-center justify-center bg-[#9e358909] py-[250px]'><Icon icon="svg-spinners:3-dots-fade" className="text-[50px] text-primary" /></div>
  };


  return (
    componentDidMount()
  )
};

export default Blog