import React,{useState} from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
//import { FaTimes } from 'react-icons/fa';
import useMediaQuery from '../hooks/useMediaQuery';
import navLogo from  '../Assets/ISimplify-logo.png';
import { navLinks } from '../Assets/data';


const NavBar = () => {
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  const isNotDesktopScreens = useMediaQuery('(max-width: 1080px)');
  const [fix, setFixed] = useState(false);

  const activeLink = 'flex gap-10 font-Kanit capitalize text-1.5xl text-primary font-[500]';
  const normalLink = 'flex gap-10 font-Kanit capitalize text-1.5xl text-dark font-[500] hover:text-primary';

  const fixedNav = () => {
    if (window.scrollY >= 16) {
      setFixed(true)
    } else {
      setFixed(false)
    }
  }

  
  window.addEventListener('scroll', fixedNav)
  return (
    <nav className= {fix ? `${"w-full bg-white py-4 shadow-sm fixed transition mb-0 lg:mb-5"}` : `${"w-full bg-white py-4 mb-0 lg:mb-5"}` } >
      <div className="flex items-center justify-between mx-auto w-5/6">
        <Link to="/" reloadDocument>
          <img src={navLogo} alt="logo" className='cursor-pointer w-[150px] h-[40px]' />
        </Link>

        {/* Desktop Menu */}
        {isNotDesktopScreens ?(
          <Icon icon="heroicons:bars-3-center-left-16-solid" className='cursor-pointer text-[22px] font-bold rotate-180' onClick = {() => setIsMenuToggled(!isMenuToggled)} />          
          ): (
            //Desktop Menu
            <div className="flex justify-between items-center gap-14 w-3.9/6">
              {navLinks.map((item) => (
                <div>
                  <NavLink 
                  to={`${item.link}`}
                  key={item.id}
                  onClick={() => {}}
                  className={({ isActive }) => isActive ? activeLink : normalLink}
                  reloadDocument
                  >
                    {item.name}
                  </NavLink>
                </div>
              ))}
            
             
              <div className="flex justify-between gap-5">
                <button className="px-6 py-2 bg-none outline-none border-solid border-2 rounded  border-primary text-primary font-semibold hover:border-secondary hover:text-secondary">
                  <a href="https://account.isimplify.ng/login"> Sign In </a>
                </button>
                <button className="px-6 py-2 bg-primary outline-none border-none rounded text-white font-semibold  hover:bg-secondary ">
                  <a href="https://account.isimplify.ng/create"> Create Account </a>
                </button>
              </div>
            </div>
          )}

          {/* Mobile Menu */}
          {isNotDesktopScreens && isMenuToggled && (
            <div className="fixed top-0 left-0 right-0 bottom-0 h-full bg-white w-full m-auto ">
              <div className='w-5/6 mx-auto transition delay-300 duration-300 ease-in-out'>
                {/* close Menu Icon */}
                <div className="flex justify-end py-7">
                <Icon icon="humbleicons:times" rotate={2} className='cursor-pointer text-[23px] font-bold' onClick = {(isMenuToggled) => setIsMenuToggled(!isMenuToggled)} />
                </div>

                {/* Menu Items */}
                <div className="flex flex-col gap-6 w-full h-full">
                  {navLinks.map((item) => (
                    <div>
                      <NavLink 
                      to={`${item.link}`}
                      key={item.id}
                      onClick={() => {}}
                      className={({ isActive }) => isActive ? activeLink : normalLink}
                      reloadDocument
                      >
                        {item.name}
                      </NavLink>
                    </div>
                  ))}
                  
                
                  <div className="flex flex-col gap-5">
                    <button className="px-6 py-2 bg-none outline-none border-solid border-2 rounded  border-primary text-primary font-semibold hover:border-secondary hover:text-secondary">
                      <a href="https://account.isimplify.ng/login" reloadDocument> Sign In </a>
                    </button>
                    <button className="px-6 py-2 bg-primary outline-none border-none rounded text-white font-semibold  hover:bg-secondary hover:text-primary">
                      <a href="https://account.isimplify.ng/create" reloadDocument> Create Account </a>
                    </button>
                  </div>

                </div>
              </div>
            </div>
          )}
      </div>
    </nav>
  )
}

export default NavBar