import React from 'react';
import {motion} from 'framer-motion';
import { process, docs } from '../Assets/data';
import DeliveryBtn from '../Assets/B1-1.png';
import PayBtn from '../Assets/B1-2.png';

const whyImage = 'https://img.freepik.com/free-vector/flat-illustration-person-shrugging_23-2149330512.jpg?size=626&ext=jpg&ga=GA1.1.1614100259.1700579036&semt=ais'

const Home = () => {
  return (
    <>
      {/* Hero Section */}
      <div className='flex lg:flex-row flex-col items-center h-full gap:10px py-10 w-5/6 mx-auto'>
        {/* Hero texts and button */}
        <div className=" lg:w-3/6 w-full my-4">
          <h3 className="text-[#181818] text-[30px] lg:text-[45px] font-Kanit font-[600] leading-12">
            Never Buy or Sell online without using <span className='text-primary'>iSimplify Trust</span>
          </h3>
          <p className='text-[#393939] font-[400] font-Inter leading-8 text-[20px] my-4'>
            With iSimplify Trust, you can buy and sell anything safely online without the risk associated with online transactions.
          </p>
          <button className="px-6 py-3 my-4 bg-primary outline-none border-none rounded text-white font-semibold  hover:bg-secondary ">
            <a href="https://account.isimplify.ng/create"> Create Free Account </a>
          </button>
        </div>
        
        {/* Hero image*/}
        <div className="lg:w-3/6 w-5/6 my-4">
          <div className="lg:w-[75%] w-full h-[380px] bg-[url('./Assets/Hero1.png')] bg-cover bg-no-repeat bg-center rounded-xl mx-auto">
              <motion.img 
                initial = "hidden"
                whileInView = "visible"
                viewport = {{once: true, amount: 0.5}}
                transition= {{duration: 1, delay: 1}} 
                variants = {{
                    hidden: {opacity: 0, y: 50},
                    visible: {opacity: 1, y: 254, x: 120},
                }}
                src={DeliveryBtn} 
                alt="popup button" 
                className="lg:w-[80%] w-[70%]" 
              />

              <motion.img 
                initial = "hidden"
                whileInView = "visible"
                viewport = {{once: true, amount: 0.5}}
                transition= {{duration: 1, delay: 2}} 
                variants = {{
                    hidden: {opacity: 0, y: 0 },
                    visible: {opacity: 1, y: 254, x: 120},
                }}
                src={PayBtn} 
                alt="popup button" 
                className="lg:w-[80%] w-[70%]" 
              />
              
          </div>
        </div>
      </div>
      {/* End of Hero Section */}


      {/* What Section */}
      <div className="my-10 w-5/6 flex flex-col lg:flex-row justify-between gap-5 py-10  mx-auto ">
        {/* Texts */}
        <div className="lg:w-2/6 w-full">
          <h1 className="text-primary font-Kanit font-[600] text-[30px]">
            What is iSimplify Trust?
          </h1>
          <p className='text-[#393939] font-[400] font-Kanit leading-8 text-[18px] my-4'>
            iSimplify Trust stands as Nigeria's most secure payment method when it comes to counterparty risk. We provide a protective shield for both buyers and sellers, ensuring that all funds exchanged through iSimplify Trust are securely held in trust.
          </p>
        </div>
        {/* End of Texts */}

        <div className="flex flex-wrap lg:w-[60%] items-center justify-center w-full gap-5 ">
            {process.map((items)=>(
              <div className="bg-white rounded-lg h-[130px] shadow-lg hover:translate-x-2 cursor-pointer transition duration-500">
                <div className="p-8">
                  <div key={items.icon} className="w-[40px] h-[40px] flex items-center justify-center rounded-full bg-[#6d798750] text-[18px] text-center text-primary">
                    {items.icon }            
                  </div>
                  <h3 key={items.text} className="text-primary font-Kanit font-[400] text-[16px] my-2">
                    {items.text}
                  </h3>
                </div>
              </div>
            ))} 
        </div>
      </div>
      {/* End of What Section */}


      {/* Why Section */}
      <div className="w-5/6 flex flex-col lg:flex-row items-center justify-center gap-10 lg:gap-24 py-10  mx-auto ">
        {/* image */}
        <div className=" pt-[70px] pb-[40px] px-[60px] lg:pt-[85px] lg:pb-[55px] lg:px-[90px] bg-[#F9F9F9] rounded-full overflow-hidden flex justify-center items-center">
            <motion.img
                initial = "hidden"
                whileInView = "visible"
                viewport = {{once: true, amount: 0.5}}
                transition= {{duration: 1, delay: 1}} 
                variants = {{
                    hidden: {opacity: 0, y: 50},
                    visible: {opacity: 1, y: 0},
                }}
            
                src={whyImage} alt="illustration" className="w-[280px] rounded-full mx-auto" 
            />
        </div>
        {/* End of Image*/}
        {/* Texts */}
        <div className="lg:w-[40%] w-full">
          <h1 className="text-primary font-Kanit font-[600] text-[30px]">
            Why iSimplify Trust?
          </h1>
          <p className='text-[#393939] font-[400] font-Kanit leading-8 text-[18px] my-4'>
            Ever faced uncertainty in online transactions? As a buyer or seller, you may hesitate when 
            the buyer asks for the product before payment or when the seller demands payment upfront 
            without assurance of product delivery. iSimplify Trust act as a 3rd party to online transactions, 
            holding funds in escrow and disbursing them quickly and securely once both the seller and buyer 
            are fully satisfied with the transaction. Thereby, protecting both buyers and sellers.
          </p>
          <button className="px-6 py-3 my-4 bg-primary outline-none border-none rounded text-white font-semibold  hover:bg-secondary ">
            <a href="https://account.isimplify.ng/create"> Create Free Account </a>
          </button>
        </div>
        {/* End of Texts */}
      </div>
      {/* End of Why Section */}


      {/* API */}    
      <div className="w-5/6 flex flex-col lg:flex-row gap-7 justify-center my-10 mx-auto">
        {/* Texts */}
        <div className="lg:w-2/6 w-full">
          <h1 className="text-primary font-Kanit font-[600] text-[30px]">
            Easy API Integration
          </h1>
          <p className='text-[#393939] font-[400] font-Kanit leading-8 text-[18px] my-4'>
            Payments for your website, marketplace, classified site, shopping cart or mobile app with no chargebacks, ever.
          </p>
          <button className="px-6 my-2 py-3 bg-none outline-none border-solid border-2 rounded  border-primary text-primary font-semibold hover:border-secondary hover:text-secondary">
            Read Docs 
          </button>
        </div>
        {/* End of Texts */}

        {/* Icon box */}
        {docs.map((item) => (
          <div className="bg-[#f9f9f9] py-8 px-2 rounded-[20px] lg:w-[30%] w-full " >
            <div className="my-2 mx-3">
              <div key={item.icon} className="w-[50px] h-[50px] text-2xl flex items-center justify-center bg-[#f9f3ef] text-[18px] text-center text-primary" style={{borderRadius: '40% 40% 50% 0'}}>
                {item.icon}
              </div>
              <div key={item.title} className="font-Kanit w-[80%] font-semibold text-[22px] text-secondary my-3 ">
                <h2>
                  {item.title}
                </h2>
              </div>
              <div key={item.text} className="font-Kanit   text-dark  ">
                <p>
                  {item.text}
                </p>
              </div>
            </div>
          </div>
        ))}
        {/* End of Icon box */}
        
      </div> 
      {/* End of API */}


      {/* Testimonial */}   
      {/*<Testimonial className="mx-auto w-5/6" /> */}
      {/* End of Testimonial */}
    </>   
  )
}

export default Home