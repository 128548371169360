import React from 'react';
import { RiVisaLine } from 'react-icons/ri';
import { RiMastercardLine } from 'react-icons/ri';
import { BsBank } from 'react-icons/bs';
import { footerLinks } from '../Assets/data';
import { NavLink, Link } from 'react-router-dom';
import logo from '../Assets/ISimplify-logo.png';

const year = new Date().getFullYear();

const Footer = () => {
  return (
    <div className="w-full mx-auto mt-5">
      <hr className="bg-[#6d798750]  mb-[40px]" />

      <div className="w-5/6 mx-auto gap-5 flex flex-wrap my-5">
        {/*footer logo*/}
        <div className="lg:w-1/6 w-3/6">
          <Link to="/" reloadDocument>
            <img src={logo} alt="iSimplify logo" className='cursor-pointer w-[150px] h-[40px]' />
          </Link>  
        </div>

        {/*footer links*/}
        {footerLinks.map((item)=>(
          <div className="lg:w-1/6 w-3/6">
            <div key={item.id} className="capitalize font-Kanit font-[500] text-[18px] text-primary">
              {item.title}
            </div>
            {item.dir.map((link) => (
              <NavLink 
              to={`${link.url}`}
              key={link.id}
              className="text-dark capitalize font-Kanit "
              reloadDocument
              >
                <p className="my-2 hover:text-secondary" key={link.id}>{link.name}</p>
              </NavLink>
            ))}
          </div>
        ))}
        

        {/*footer contact*/}
        <div className="lg:w-1/6 w-3/6">
          <div className="capitalize font-Kanit font-[500] text-[18px] text-primary">
            Contact
          </div>
          <p className="my-2 hover:text-secondary text-dark font-Kanit" >
             <a href="mailto:support@isimplify.ng">support@isimplify.ng</a>
          </p>
          <p className="my-2 hover:text-secondary  text-dark capitalize font-Kanit" >
            <a href="tel:+2349027520591">+234 902 752 0591</a>
          </p>
          <p className="my-2 hover:text-secondary text-dark capitalize font-Kanit" >
            <a href="https://wa.me/+2349027520591?text=Hello!">WhatsApp Support</a>
          </p>
        </div>

      </div>  
      

      {/* footer base */}
      <div className="w-5/6 mx-auto items-center lg:justify-between justify-center my-14">
        <p className="my-2 text-dark font-Kanit text-center" >
          Copyright 2022 - {year}, iSimplify. All Rights Reserved.
        </p>
        <p className="my-2 text-dark capitalize font-Kanit text-center flex flex-row items-center justify-center gap-3" >
          Security
          <span className="text-xl"><RiMastercardLine /></span>
          <span className="text-xl"><RiVisaLine /></span>
          <span className="text-xl"><BsBank /></span>
        </p>
      </div>
    </div>
  )
}

export default Footer




















