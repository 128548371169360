import React, { /*useRef, useState*/ } from 'react';
import { testimonial } from '../Assets/data';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// pagination module
import { Pagination } from 'swiper/modules';

const Testimonial = () => {
  return (
    <div>
      <Swiper
        grabCursor={true}
        loop={true}
        slidesPerView={1}
        direction={'vertical'}
        pagination={{
          el: '.swiper-pagination',
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper flex flex-col lg:flex-row w-5/6 items-center"
      >
        <div className="w-1/6">
            <div className="swiper-pagination">

            </div>
        </div>
        <SwiperSlide>
            {testimonial.map((item) => (
                <div className="flex flex-col lg:flex-row w-5/6">
                    <div className="rounded-xl" key={item.image}>
                        <img src={item.image} alt="testimonier" className="w-20px h-20px" />
                    </div>
                    <div className="">
                        <h3 className="font-Kanit" key={item.word}>
                            {item.word}
                        </h3>
                        <p className="font-Kanit" key={item.name}>
                            {item.name}
                        </p>
                    </div>
                </div>
            ))}
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Testimonial