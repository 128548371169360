import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { NavBar, Footer} from './Components/Index';
import { About, Blog, Delivery, Developer, Documentation, Escrow, Faqs, Home, MarketPlace, Pricing, Store } from './pages/Index';
import { SignIn, CreateAccount } from './views/Index';
import './App.css'


const App = () => {

  return (
    <div className='app'>
      <BrowserRouter>
        <NavBar />
        <div>
          <Routes>
            {/* NavLinks  */}
            <Route path="/" element={(<Home/>)} />
            <Route path="/developer" element={(<Developer/>)} />
            <Route path="/pricing" element={(<Pricing/>)} />
            <Route path="/blog" element={(<Blog/>)} />
            <Route path="/about" element={(<About/>)} />
            <Route path="/delivery" element={(<Delivery/>)} />
            <Route path="/documentation" element={(<Documentation/>)} />
            <Route path="/escrow" element={(<Escrow/>)} />
            <Route path="/faqs" element={(<Faqs/>)} />
            <Route path="/marketplace" element={(<MarketPlace/>)} />
            <Route path="/store" element={(<Store/>)} />
            {/* End of NavLinks  */}

            {/* Auth */}
            <Route path="/sign_in" element={(<SignIn/>)} />
            <Route path="/create_account" element={(<CreateAccount/>)} />
            {/* End of Auth */}
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App