import React from 'react';
import { motion } from 'framer-motion';
import { FaCheck } from 'react-icons/fa'


const pricingImg = 'https://img.freepik.com/free-vector/transactional-marketing-abstract-illustration_335657-5035.jpg?w=740&t=st=1701701575~exp=1701702175~hmac=167eb947486f57d148904e02840fb5791ae3ae6eeab442baf9627c56275357c4'

const Pricing = () => {
  return (
    <>
      {/*Pricing Header Text*/}
      <div className="py-3 lg:py-5 w-full"></div>{/*Used as a spacer*/}
      <div className="w-5/6 mx-auto">
        <h1 className="text-primary text-[28px] lg:text-[35px] font-Kanit font-[500] text-center pt-8 pb-4">Simple, fair and transparent pricing</h1>
        <p className="font-Kanit text-[16px] text-dark text-center w-[80%] lg:w-[45%] mx-auto mb-5">
          Our pricing model is designed to be simple, fair, and transparent, ensuring you know exactly what you're paying for. 
          With iSimplify Trust, there are no hidden fees or surprises.
        </p>
      </div>
      {/*End of Pricing Header Text*/}


      {/* Pricing Section*/}
      <div className=" flex w-5/6 bg-[#60164f] rounded-xl py-8 px-2 mx-auto my-12 flex-col lg:flex-row gap-5 justify-between">
        {/* image */}
        <div className=" w-5/6 lg:w-3/6 overflow-hidden flex justify-center items-center mx-auto">
            <motion.img
                initial = "hidden"
                whileInView = "visible"
                viewport = {{once: true, amount: 0.5}}
                transition= {{duration: 1, delay: 1}} 
                variants = {{
                    hidden: {opacity: 0, y: 50},
                    visible: {opacity: 1, y: 0},
                }}
            
                src={pricingImg} alt="illustration" className="w-[420px] rounded-full mx-auto" 
            />
        </div>
        {/* End of Image*/}


        {/* Pricing*/}
        <div className=" py-4 rounded-xl w-5/6 lg:w-3/6 mx-auto">
          <div className="flex rounded-[8px] bg-secondary py-4 px-5  w-[100%] lg:w-[65%] justify-between mx-auto">
            <h2 className="text-[#f9f9f9] font-Kanit">Incoming</h2>
            <h2 className="text-[#f9f9f9] font-Kanit">NGN 10,000</h2>
          </div>

          <div className="rounded-[8px] mx-auto w-[100%] lg:w-[65%] my-4  text-center">
            <h2 className="text-[#f9f9f9] font-Kanit">Our fee per transaction is 3%</h2>
          </div>

          <div className="flex rounded-[8px] bg-secondary py-4 px-5 w-[100%] lg:w-[65%] justify-between mx-auto">
            <h2 className="text-[#f9f9f9] font-Kanit">iSimplify Fee</h2>
            <h2 className="text-[#f9f9f9] font-Kanit">NGN 300</h2>
          </div>

          <div className="flex rounded-[8px] bg-secondary py-4 my-5 px-5 w-[100%] lg:w-[65%] justify-between mx-auto">
            <h2 className="text-[#f9f9f9] font-Kanit">Outgoing</h2>
            <h2 className="text-[#f9f9f9] font-Kanit">NGN 9,700</h2>
          </div>

          {/* Pricing Features*/}
          <div className="flex w-[100%] lg:w-[65%] gap-3 items-center mx-auto my-3">
            <div className="rounded-full p-[5px] bg-white flex items-center text-primary text-[12px]">
              <FaCheck />
            </div>
            <h2 className="text-[#f9f9f9] font-Kanit">Maximum fee per transaction is NGN 10,000</h2>
          </div>
          <div className="flex w-[100%] lg:w-[65%] gap-3 items-center mx-auto">
            <div className="rounded-full p-[5px] bg-white flex items-center text-primary text-[12px]">
              <FaCheck />
            </div>
            <h2 className="text-[#f9f9f9] font-Kanit">Minimum fee per transaction is NGN 300</h2>
          </div>
          <div className="flex w-[100%] lg:w-[65%] gap-3 items-center mx-auto my-3">
            <div className="rounded-full p-[5px] bg-white flex items-center text-primary text-[12px]">
              <FaCheck />
            </div>
            <h2 className="text-[#f9f9f9] font-Kanit">No hidden charges</h2>
          </div>
          <div className="flex w-[100%] lg:w-[65%] gap-3 items-center mx-auto">
            <div className="rounded-full p-[5px] bg-white flex items-center text-primary text-[12px]">
              <FaCheck />
            </div>
            <h2 className="text-[#f9f9f9] font-Kanit">No setup fee</h2>
          </div>
          {/* End Of Pricing Features*/}

        </div>

        {/* End Of Pricing*/}
      </div>

      {/* End of Pricing Section*/}
    </>
  )
}

export default Pricing